import React, { createContext, ReactNode, useContext } from "react";

type CurrentTabContextProps = {
    currentTab: string;
    setCurrentTab: (tab: string) => void;
}

const CurrentTabContext = createContext<CurrentTabContextProps | undefined>(undefined);

const CurrentTabProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentTab, setCurrentTab] = React.useState("home");

    return (
        <CurrentTabContext.Provider value={{ currentTab, setCurrentTab }}>
            {children}
        </CurrentTabContext.Provider>
    );
}

const useCurrentTabContext = () => {
    const currentTabContext = useContext(CurrentTabContext);
    if (!currentTabContext) {
        return {
            currentTab: "home",
            setCurrentTab: () => {}
        }
    }

    return currentTabContext
}

export { CurrentTabContext, CurrentTabProvider, useCurrentTabContext }