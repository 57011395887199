import React, { ReactNode } from "react";
import {
  ChakraProvider,
} from "@chakra-ui/react"
import theme from "./theme"
import TopBar, { TabInfo } from "./layout/TopBar"
import ReactGA from "react-ga";
import CookieConsent from "./shared/CookieConsent/CookieConsent";
import { Navigate, Route, Routes } from "react-router"
import Home from "./pages/home/Home";
import OOH from "./pages/ooh/OOH";
import { mainTabs, oohTabs } from "./layout/tabs";
import { CurrentTabProvider } from "./context/CurrentTabContext";

ReactGA.initialize("G-S48777ZCB2")

export const App = () => {


  const Layout = ({children, tabs}:{children:ReactNode, tabs: TabInfo[]}) => {
    return <>
    <TopBar tabs={tabs}/>
    {children}
    </>
  }

  return (
    <CurrentTabProvider>
    <ChakraProvider theme={theme}>
      <CookieConsent/>
      <Routes>
        <Route index path="/" element={<Layout tabs={mainTabs}><Home /></Layout>} />
        <Route path="/ooh" element={<Layout tabs={oohTabs}><OOH/></Layout>}/>
        <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
      </Routes>
    </ChakraProvider>
    </CurrentTabProvider>
  )
}
