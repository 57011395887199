import * as React from "react"
import { IDS } from "../../shared/Constants";
import Content from "../../shared/Content";
import Start from "./content/Start";
import { VStack } from "@chakra-ui/react";
import Contact from "./content/Contact";
import { useCurrentTabContext } from "../../context/CurrentTabContext";

const OOH = () => {
    const {currentTab, setCurrentTab} = useCurrentTabContext();

    return (<VStack spacing={8} >
        <Content index={0} id={IDS.OOH.HOME} title="Home" setCurrentTab={setCurrentTab}
            isCurrentTab={currentTab === IDS.OOH.HOME}>
            <Start />
        </Content>
        <Content index={5} id={IDS.CONTACT} title="Contact" setCurrentTab={setCurrentTab} isCurrentTab={currentTab === IDS.CONTACT}>
            <Contact />
        </Content>
    </VStack>
    )
}

export default OOH;
