import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { Box, Button, SlideFade, Stack, StackDirection, Text, useBreakpointValue } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { useCurrentTabContext } from "../../context/CurrentTabContext";

const CookieConsent = () => {
    const {currentTab} = useCurrentTabContext();
    const [consent, setConsent] = useState(localStorage.getItem('boxcvCookieConsent') || '')
    const responsiveWidth = useBreakpointValue<StackDirection>({
        base: "column",
        sm: "row",
    });

    useEffect(() => {
        if (consent === 'accepted') {
            ReactGA.pageview(currentTab);
        }
    }, [consent, currentTab]);

    const handleAccept = () => {
        setConsent('accepted');
        localStorage.setItem('boxcvCookieConsent', 'accepted')
    }

    const handleReject = () => {
        setConsent('rejected');
        localStorage.setItem('boxcvCookieConsent', 'rejected')
    }


    return (
        <SlideFade in={consent === ''}  >
            <Box pos={'fixed'} bottom={0} w={"100%"} backgroundColor={"#FFF"} zIndex={99} px={10} style={{
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(255, 255, 255, 0.6)"
            }}>
                <Stack direction={responsiveWidth} justifyContent={"center"} mb={5} mt={5} alignItems={"center"} textAlign={responsiveWidth === "column" ? "center" : undefined}>
                    <Text color={"black"} fontFamily={"Open Sans, sans-serif"}>
                        <Trans i18nKey={"cookieConsent.disclaimer"}>
                            We use cookies to enhance your browsing experience on this website. By continuing, you agree to the use of cookies on this website.
                        </Trans>
                    </Text>
                    <Button bg={"primary.main"} color={"primary.text"} onClick={handleAccept} minW={"fit-content"} ms={responsiveWidth === "row" ? 2 : undefined}>
                        <Trans i18nKey={"cookieConsent.accept"}>
                            Accept
                        </Trans>
                    </Button>
                    <Button variant={"outline"} color={"primary.main"} onClick={handleReject} minW={"fit-content"} >
                        <Trans i18nKey={"cookieConsent.reject"}>
                            Reject
                        </Trans>
                    </Button>
                </Stack>
            </Box>
        </SlideFade>
    )
}

export default CookieConsent;